import useGlobal from "@/hooks/useGlobal";
import { Buttons } from "@/ui/Buttons";
import { useSourceFlowText } from "@sourceflow-uk/sourceflowtext";
import cn from "classnames";
import { Stack } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";

import { useMediaQuery } from "@/hooks/useMediaQuery";
import brochure from "./brochure.webp";
import classes from "./styles.module.scss";

export const FooterBrochure = ({ className = "" }) => {
  const [md] = useMediaQuery();
  const global = useGlobal();
  const EditableText = useSourceFlowText({ global, prefix: "footer.brochure", type: "text" });

  return (
    <Stack className={cn(className, classes.FooterBrochure)}>
      <div>
        <EditableText tag="h3" path="title">
          Download
          <br /> Our Brochure
        </EditableText>
        <Buttons
          buttons={[{
            label: "Download now",
            url: "https://hrs.sites.sourceflow.co.uk/assets/pdf/HRS-Brochure.pdf",
            variant: "cyan",
            target: "_blank"
          }]} />
      </div>
      <Parallax easing="easeOut" translateY={md ? [0, 0] : [-100, 20]}>
        <img src={brochure} alt="" />
      </Parallax>
    </Stack>
  );
};
