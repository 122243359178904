import useGlobal from "@/hooks/useGlobal";
import useOffices from "@/hooks/useOffices";
import { OfficeCard } from "@/ui/OfficeCard";
import { useSourceFlowText } from "@sourceflow-uk/sourceflowtext";
import cn from "classnames";
import { Col, Row } from "react-bootstrap";

export const FooterOffices = ({ className }) => {
  const global = useGlobal();
  const offices = useOffices();
  const EditableText = useSourceFlowText({ global, prefix: "footer.offices", type: "text" });

  return (
    <div className={cn(className)}>
      <EditableText tag="h3" path="title" className="h2 mb-4">
        Keep in Touch
      </EditableText>
      <Row>
        {offices.map((office, idx) => (
          <Col key={idx} xs={12} md={3} className="mb-4">
            <OfficeCard {...office} google_maps_link={false} size="small" />
          </Col>
        ))}
      </Row>
    </div>
  );
};
