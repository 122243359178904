import { useMediaQuery as __useMediaQuery } from "@react-hook/media-query";
import { useEffect, useState } from "react";

export const useMediaQuery = (query = "only screen and (max-width: 992px)") => {
  const mq = __useMediaQuery(query);
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(mq);
  }, [mq]);

  return [state, setState];
};
