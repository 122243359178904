import useAwards from "@/hooks/useAwards";
import cn from "classnames";
import { Stack } from "react-bootstrap";

import classes from "./styles.module.scss";

export const FooterAwards = ({ className = "" }) => {
  const awards = useAwards().filter((a) => !!a.show_on_footer);

  return (
    <Stack className={cn(className, classes.FooterAwards)}>
      {awards.map(({ id, image }) => (
        <div key={id}>
          <img src={image} alt="" />
        </div>
      ))}
    </Stack>
  );
};
