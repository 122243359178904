import cn from "classnames";
import { Col, Container, Row } from "react-bootstrap";

import { FooterAwards } from "./components/FooterAwards";
import { FooterBrochure } from "./components/FooterBrochure";
import { FooterCopyright } from "./components/FooterCopyright";
import { FooterMenu } from "./components/FooterMenu";
import { FooterOffices } from "./components/FooterOffices";
import { FooterSocials } from "./components/FooterSocials";

import classes from "./styles.module.scss";

export default function ({ className = "bg-navy text-white", theme = "dark" }) {
  return (
    <footer className={cn(className, classes.Footer)} data-bs-theme={theme}>
      <Container fluid="xxl">
        <Row className="mb-md-5 flex-column-reverse flex-md-row">
          <Col xs={12} lg={8} className="pt-5">
            <FooterOffices />
          </Col>
          <Col xs={12} lg={4} className="pt-4 pt-lg-6 pt-xxl-4">
            <FooterBrochure className="flex-column-reverse flex-md-row text-center text-md-start" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={8}>
            <FooterSocials className="text-center text-md-start" />
          </Col>
          <Col xs={12} lg={4}>
            <FooterAwards className="flex-row justify-content-center justify-content-md-start gap-2" />
          </Col>
        </Row>
        <hr className="mb-0 mt-5 mt-md-2" />
        <Row className="d-flex align-items-center py-5">
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column flex-md-row align-items-center gap-2 gap-md-4 mb-4 mb-md-0"
          >
            <img src="/assets/LogoWhite.svg" className="mb-4 mb-md-0" width={149} alt="" />
            <FooterMenu className="justify-content-center justify-content-md-start" />
          </Col>
          <Col xs={12} md={6}>
            <FooterCopyright className="flex-column flex-md-row justify-content-between align-items-center text-center gap-3" />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
