import useGlobal from "@/hooks/useGlobal";
import useSocialLinks from "@/hooks/useSocialLinks";
import { SocialLink } from "@/ui/SocialLink";
import { useSourceFlowText } from "@sourceflow-uk/sourceflowtext";
import cn from "classnames";
import { Stack } from "react-bootstrap";

import classes from "./styles.module.scss";

export const FooterSocials = ({ className = "" }) => {
  const socials = useSocialLinks();
  const global = useGlobal();
  const EditableText = useSourceFlowText({ global, prefix: "footer.socials", type: "html" });

  return (
    <div className={cn(className, classes.FooterSocials)}>
      <EditableText path="description">
        Any Questions? Email us at:{" "}
        <a className="large fw-bold" href="mailto:info@hyperec.com">
          info@hyperec.com
        </a>
      </EditableText>
      <Stack className="flex-row justify-content-center justify-content-md-start gap-2 py-4">
        {socials.map(({ id, href, icon }) => (
          <SocialLink key={id} href={href} icon={icon} />
        ))}
      </Stack>
    </div>
  );
};
