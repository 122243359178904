import useGlobal from "@/hooks/useGlobal";
import { useSourceFlowText } from "@sourceflow-uk/sourceflowtext";
import cn from "classnames";
import { Stack } from "react-bootstrap";

import classes from "./styles.module.scss";

export const FooterCopyright = ({ className = "" }) => {
  const global = useGlobal();
  const EditableText = useSourceFlowText({ global, prefix: "footer.copyright", type: "text" });

  return (
    <Stack className={cn(className, classes.FooterCopyright)}>
      <EditableText tag="span" path="text" className="xsmall">
        &copy; Copyright Hyper Recruitment Solutions 2024. Registration Number: 07747824
      </EditableText>
      <img src="/assets/SiteBySourceFlow.svg" alt="site by Sourceflow" />
    </Stack>
  );
};
